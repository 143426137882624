








































.daily-beet-web-layout {
  display: grid;
  grid-template-areas:
    '. header header .'
    'left left right right'
    'footer-left footer-left . footer-right';
}
